import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css"
import PageNotFound from './components/PageNotFound';

ReactDOM.render(
  <React.StrictMode>
    <PageNotFound />
  </React.StrictMode>
,
	document.getElementById('root')
);

reportWebVitals();
