import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

const PageNotFound = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const containerWidth = isMobile ? '95vw' : '50vw';

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: isMobile ? '100svh' : '100vh',
        padding: '1rem',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ width: containerWidth, textAlign: 'center' }}>
        {/* Logo Section */}
        <div style={{ padding: '', textAlign: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="https://storage.googleapis.com/calendarapp-data-bucket/Logo/logo192.png"
              alt="CalendarApp Logo"
              style={{ height: '80px' }}
            />
          </div>
        </div>

        {/* Title */}
        <h1 style={{ marginBottom: '1rem' }}>Page Not Found</h1>

        {/* Message */}
        <p style={{ marginBottom: '1rem', fontWeight: 'bold' }}>
          The page you are looking for has moved or does not exist.
          <br />
          Navigate back to your Dashboard.
        </p>

        {/* Back to Dashboard Button */}
        <button
          style={{
            backgroundColor: '#4CAF50',
            border: '2px solid #4CAF50',
            fontWeight: 'bold',
            color: 'white',
            fontSize: '16px',
            margin: '10px auto',
            borderRadius: '4px',
            padding: '12px 20px',
            cursor: 'pointer',
            transition: 'box-shadow 0.5s',
          }}
          onClick={() => {
            window.location.href = '/events';
          }}
        >
          Back to Dashboard
        </button>

        {/* 404 Illustration */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '0',
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/calendarapp-303815.appspot.com/o/logos%2F404%20Error-rafiki.png?alt=media&token=20f7f99f-ee54-48fb-b2c1-0659da2e1ce2"
            alt="404 Error Illustration"
            style={{ width: '50%', height: 'auto' }}
          />
        </div>

        {/* Attribution */}
        <div>
          <a
            href="https://storyset.com/web"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: '12px', color: '#999' }}
          >
            Web illustrations by Storyset
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
